export default {
  ENVIRONMENT: 'staging',
  URL: {
    BASEBACK: 'https://staging-api.soreto.com',
    BASEFRONT: 'https://staging.soreto.com',
    SASS_API: 'https://sass-api.soreto.com',
    BASEREPORT: 'https://staging-report.soreto.com/#/pages/admin/reports/salesman-monthly-commission-report',
    NEWANALYTICS: 'https://staging-analytics.soreto.com/admin/dashboard/',
    NEWFRONT: 'https://staging-front.soreto.com',
    MELISSA_PORTAL: 'https://melissa.soreto.com',
  },
  API: {
    BASEURL: 'https://staging-api.soreto.com/api/v1'
  },
  IMG: {
    SRC: 'https://s3-eu-west-1.amazonaws.com/s3-reverb-images',
    UPLOAD_LIMIT: 2000000
  },
  ANALYTICS: {
    GA: {
      ID: 'UA-79262010-1'
    },
    SEGMENT: {
      WRITE_KEY: ''
    },
    MIXPANEL: {
      TOKEN: ''
    }
  },
  BI : {
    API: {
      BASEURL: 'https://ginzil840d.execute-api.eu-west-1.amazonaws.com/soretobi/api/reprocess'
    }
  },
  MARKETPLACE : {
    URL: 'https://staging-b2c.soreto.com/'
  },
  MENU:{
    CLIENT:[
      // {route:'/client/dashboard',name:'Dashboard',icon:'fa fa-th'},
      // {route:'/client/reports',name:'Reports',icon:'fa fa-file-text'},
      // {route:'/client/analytics',name:'Analytics', className:'analytics' },
      {route:'/client/analyticsBi',name:'Analytics', className:'analytics', beta: false },
      // {route:'/client/byChannel',name:'By Channel', className:'byChannel'},
      {route:'/client/byChannelBi',name:'By Channel', className:'byChannel', beta: false},//,
      //{route:'/client/activity',name:'Activity', className:'activity'}
      {route:'/client/userData',name:'User Data', className:'users', beta: false }
    ],USER:[
      {route:'/user/dashboard',name:'Dashboard'},
      {route:'/user/reports',name:'Reports'},
      {route:'/user/activity',name:'Activity'},
      {route:'/user/transactions',name:'Transactions'},
      {route:'/user/socialpost',name:'Share'}
    ]
  },
  SOCIAL: {
    FACEBOOK: {
      SORETO_URL: 'https://www.facebook.com/LoveSoreto/',
      SHAREURL: 'https://www.facebook.com/sharer/sharer.php?u=',
      ICONCLASS: 'fa fa-facebook',
      TITLE: 'Facebook',
      API_SUPPORT: true,
      URL_SUPPORT: true
    },
    TWITTER: {
      SORETO_URL: 'https://twitter.com/lovesoreto',
      SHAREURL: 'https://twitter.com/intent/tweet?text=',
      ICONCLASS: 'fa fa-twitter',
      TITLE: 'Twitter',
      API_SUPPORT: true,
      URL_SUPPORT: true
    },
    PINTEREST: {
      SORETO_URL: '',
      SHAREURL: 'https://www.pinterest.com/pin/create/button/?url=',
      ICONCLASS: 'fa fa-pinterest',
      TITLE: 'Pinterest',
      API_SUPPORT: 'true' === 'true' ? true : false,
      URL_SUPPORT: true
    },
    GOOGLE: {
      SORETO_URL: 'https://plus.google.com/103376066758744153842',
      SHAREURL: 'https://plus.google.com/share?url=',
      ICONCLASS: 'fa fa-google-plus',
      TITLE: 'Google+',
      API_SUPPORT: false,
      URL_SUPPORT: true
    },
    INSTAGRAM: {
      SORETO_URL: 'https://www.instagram.com/lovesoreto/',
      SHAREURL: null,
      ICONCLASS: 'fa fa-instagram',
      TITLE: 'Instagram',
      API_SUPPORT: false,
      URL_SUPPORT: false
    },
    LINKEDIN: {
      SORETO_URL: 'https://www.linkedin.com/company-beta/11070643/',
      SHAREURL: null,
      ICONCLASS: 'fa fa-linkedin',
      TITLE: 'Linkedin',
      API_SUPPORT: false,
      URL_SUPPORT: false
    }
  },
  TAGS: ['reverb-order-value','reverb-order-id','reverb-line-items','reverb-line-item','reverb-line-item-name',
    'reverb-line-item-description','reverb-line-item-sku','reverb-line-item-quantity','reverb-line-item-price', 'reverb-line-item-category', 'reverb-currency'],
  WITHDRAWAL_REQUEST_STATUSES: ['PENDING','APPROVED', 'PAID', 'REJECTED', 'CANCELLED'],
  REMOVAL_ALLOWED_CLIENTS: ['5cee4f92ee9c6f5a7e11b331','5e4d46c1c381582425de597f', '5e4d476660064b243fa2ed72', '5e4d486db1fc092459674c1a', '5cbedccd3fc7b320bc05da38']
};